import * as t from "io-ts";
import { nanoid } from "nanoid";

interface FormIdBrand {
    readonly FormId: unique symbol;
}
export const FormId = t.brand(t.string,
    (n): n is t.Branded<string, FormIdBrand> => true,
    "FormId");
export type FormId = t.TypeOf<typeof FormId>;

interface FormShortIdBrand {
    readonly FormShortId: unique symbol;
}
export const FormShortId = t.brand(t.string,
    (n): n is t.Branded<string, FormShortIdBrand> => true,
    "FormShortId");
export type FormShortId = t.TypeOf<typeof FormShortId>;

interface ThemeIdBrand {
    readonly ThemeId: unique symbol;
}
export const ThemeId = t.brand(t.string,
    (n): n is t.Branded<string, ThemeIdBrand> => true,
    "ThemeId");
export type ThemeId = t.TypeOf<typeof ThemeId>;

interface ThankYouPageIdBrand {
    readonly ThankYouPageId: unique symbol;
}
export const ThankYouPageId = t.brand(t.string,
    (n): n is t.Branded<string, ThankYouPageIdBrand> => true,
    "ThankYouPageId");
export type ThankYouPageId = t.TypeOf<typeof ThankYouPageId>;

interface PageIdBrand {
    readonly PageId: unique symbol;
}
export const PageId = t.brand(t.string,
    (n): n is t.Branded<string, PageIdBrand> => true,
    "PageId");
export type PageId = t.TypeOf<typeof PageId>;

interface PagePropertiesIdBrand {
    readonly PagePropertiesId: unique symbol;
}
export const PagePropertiesId = t.brand(t.string,
    (n): n is t.Branded<string, PagePropertiesIdBrand> => true,
    "PagePropertiesId");
export type PagePropertiesId = t.TypeOf<typeof PagePropertiesId>;

interface ComponentIdBrand {
    readonly ComponentId: unique symbol;
}
export const ComponentId = t.brand(t.string,
    (n): n is t.Branded<string, ComponentIdBrand> => true,
    "ComponentId");
export type ComponentId = t.TypeOf<typeof ComponentId>;

interface TagIdBrand {
    readonly TagId: unique symbol;
}
export const TagId = t.brand(t.string,
    (n): n is t.Branded<string, TagIdBrand> => true,
    "TagId");
export type TagId = t.TypeOf<typeof TagId>

interface IntegrationIdBrand {
    readonly IntegrationId: unique symbol;
}
export const IntegrationId = t.brand(t.string,
    (n): n is t.Branded<string, IntegrationIdBrand> => true,
    "IntegrationId");
export type IntegrationId = t.TypeOf<typeof IntegrationId>

interface BetaTesterIdBrand {
    readonly BetaTesterId: unique symbol;
}
export const BetaTesterId = t.brand(t.string,
    (n): n is t.Branded<string, BetaTesterIdBrand> => true,
    "BetaTesterId");
export type BetaTesterId = t.TypeOf<typeof BetaTesterId>;

interface AssetIdBrand {
    readonly AssetId: unique symbol;
}
export const AssetId = t.brand(t.string,
    (n): n is t.Branded<string, AssetIdBrand> => true,
    "AssetId");
export type AssetId = t.TypeOf<typeof AssetId>;

interface PurgeQueueIdBrand {
    readonly PurgeQueueId: unique symbol;
}
export const PurgeQueueId = t.brand(t.string,
    (n): n is t.Branded<string, PurgeQueueIdBrand> => true,
    "PurgeQueueId");
export type PurgeQueueId = t.TypeOf<typeof PurgeQueueId>;

interface UserIdBrand {
    readonly UserId: unique symbol;
}
export const UserId = t.brand(t.string,
    (n): n is t.Branded<string, UserIdBrand> => true,
    "UserId");
export type UserId = t.TypeOf<typeof UserId>;

interface SubmissionIdBrand {
    readonly SubmissionId: unique symbol;
}
export const SubmissionId = t.brand(t.string,
    (n): n is t.Branded<string, SubmissionIdBrand> => true,
    "SubmissionId");
export type SubmissionId = t.TypeOf<typeof SubmissionId>;

interface SubmissionUuidBrand {
    readonly SubmissionUuid: unique symbol;
}
export const SubmissionUuid = t.brand(t.string,
    (n): n is t.Branded<string, SubmissionUuidBrand> => true,
    "SubmissionUuid");
export type SubmissionUuid = t.TypeOf<typeof SubmissionUuid>;

export function newSubmissionId() {
    const submissionId = SubmissionId.decode(nanoid());
    if (submissionId._tag === "Left") {
        throw new Error("Die neue Id ist ungültig!");
    }

    return submissionId.right;
}
