import { logHttpError } from "./error-utils";

export class HttpError extends Error {
    constructor(
        readonly status: number,
        message?: string | undefined,
        readonly debugMessage?: string | undefined) {
        super(message);
        logHttpError(status, message, debugMessage);
    }
}
