export function logError(message?: string | undefined, debugMessage?: string | undefined) {
  if (process.env.NODE_ENV === "development") {
      console.error(`Error ${message}\n${debugMessage}`);
  }
}

export function logHttpError(status: number, message?: string | undefined, debugMessage?: string | undefined) {
  if (process.env.NODE_ENV === "development") {
      console.error(`Http-Status: ${status} ${message}\n${debugMessage}`);
  }
}
