import { HttpError } from "./http-error";
import * as t from "io-ts";
import PrettyPrintIoTsErrors from "./io-ts/PrettyPrintIoTsErrors";
import { Has } from "ts-toolbelt/out/Union/Has";
import { logHttpError } from "./error-utils";

export class BadResponseBodyError extends HttpError {
    static logAndFallback<A>(status: number, decoded: t.Validation<A>) : Has<A, undefined> extends 1 ? never : A | undefined
    static logAndFallback<A, F>(status: number, decoded: t.Validation<A>, fallback: F) : A | F
    static logAndFallback<A, F>(status: number, decoded: t.Validation<A>, fallback?: F) {
        if (decoded._tag === "Left") {
            logHttpError(status, "Bad response body", PrettyPrintIoTsErrors(decoded.left));
            return fallback;
        }

        return decoded.right;
    }

    static throwIfLeft<A>(status: number, decoded: t.Validation<A>) {
        if (decoded._tag === "Left") {
            throw new BadResponseBodyError(status, "Bad response body", PrettyPrintIoTsErrors(decoded.left));
        }

        return decoded.right;
    }

    constructor(
        status: number,
        message: string | undefined,
        debugMessage?: string | undefined) {
        super(status, message, debugMessage);
    }
}
