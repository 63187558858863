import { FunctionComponent } from "react";

type SpinnerProps = {
  size?: "tiny" | "small" | "" | "large";
};

const Spinner: FunctionComponent<SpinnerProps> = ({ size }) => {
  let spinnerSize = 6;
  if (size === "tiny") {
    spinnerSize = 4;
  } else if (size === "small") {
    spinnerSize = 5;
  } else if (size === "large") {
    spinnerSize = 8;
  }
  return (
    <div
      className={`h-${spinnerSize} w-${spinnerSize} rounded-full border-2 animate-spin`}
      style={{
        borderTopColor: "#0e0",
        borderLeftColor: "#f6f",
        borderRightColor: "#0dd",
        borderBottomColor: "#f90",
      }}
    />
  );
};

export default Spinner;
