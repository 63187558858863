import { FocusableRef } from "@react-types/shared";
import React, { ReactNode } from "react";
import { ButtonProps } from "../../../interfaces/button";
import { Text } from "../Text/Text";
import Spinner from "../Spinner/Spinner";

function _Button(props: ButtonProps, _ref: FocusableRef) {
  const {
    elementType: ElementType = "button",
    children,
    variant,
    size,
    isDisabled,
    isLoading,
    className,
    showTextWhileLoading,
    hasSucceeded,
    isCanceled,
    onPress,
    ...otherProps
  } = props;
  const buttonProps: { [key: string]: unknown } = { ...otherProps };

  let buttonClassNames = "";
  if (isCanceled) {
    buttonProps.disabled = true;
    buttonClassNames = "canceled";
  }
  else if (hasSucceeded) {
    buttonProps.disabled = true;
    buttonClassNames = "success";
  } else if (isDisabled) {
    buttonClassNames = "disabled";
  }

  let content;
  if (isLoading) {
    if (showTextWhileLoading) {
      content = (
        <div className="flex">
          <div className="mr-2">
            <Spinner size={size} />
          </div>
          <Text>{children}</Text>
        </div>
      );
    } else {
      content = (
        <div className="flex justify-center">
          <Spinner size={size} />
        </div>
      );
    }
  } else {
    content = typeof children === "string" ? <Text>{children}</Text> : children;
  }

type ElementType = string | React.JSXElementConstructor<{children: ReactNode}>;

  return (
    <ElementType
      className={`btn ${size ?? ""} btn-${variant} ${buttonClassNames} ${className ?? ""}`}
      onClick={onPress}
      {...buttonProps}
    >
      {content}
    </ElementType>
  );
}

/**
 * Buttons allow users to perform an action or to navigate to another page.
 * They have multiple styles for various needs, and are ideal for calling attention to
 * where a user needs to do something in order to move forward in a flow.
 */
const Button = React.forwardRef(_Button);
Button.defaultProps = {
  size: "",
};
export default Button;
